import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LandingPage from './components/LandingPage';

const App = () => {
    const theme = createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: "#272838"
            },
            background: {
                default: "#ebedf0"
            }
        },
        typography: {
            fontFamily: [
                "Roboto"
            ]
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <LandingPage />
            </CssBaseline>
        </ThemeProvider>
    );
};

export default App;