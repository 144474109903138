import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import Box from "@mui/material/Box";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AreaChart from "./AreaChart";
import ScatterPlot from "./ScatterPlot";
import { useTheme } from "@mui/material";


const pages = ['Home', 'Journal', 'Publications'];

const createData = (name, calories, fat, carbs, protein) => {
    return { name, calories, fat, carbs, protein };
}

const cardData = [
    {
        name: 'apu',
        image: "https://ih1.redbubble.net/image.515934316.2479/st,small,507x507-pad,600x600,f8f8f8.u9.jpg",
        description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica'
    }, {
        name: 'pepe',
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiYy-oC_DKPpJkWdnC0zD5YtsdsmTg5XT0hg&usqp=CAU",
        description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica'
    }
];

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3)
];

const LandingPage = () => {
    const theme = useTheme();
    return (
        <div>
            <AppBar position="static" sx={{ marginBottom: 2 }}>
                <Toolbar sx={{ justifyContent: 'center' }}>
                    {
                        pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => { }}
                                sx={{ my: 2, color: 'white', display: 'block' }}>
                                {page}
                            </Button>
                        ))
                    }
                </Toolbar>
            </AppBar>
            <Box sx={{
                [theme.breakpoints.up('sm')]: {
                    paddingLeft: '8px',
                    paddingRight: '8px'
                }
            }}>
                <Grid sx={{
                    [theme.breakpoints.up('sm')]: {
                        padding: '8px'
                    },
                    [theme.breakpoints.down('sm')]: {
                        padding: '4px'
                    },

                }} container spacing={2}>
                    <Grid key={0} item xs={12}>
                        <Paper sx={{ padding: 2, borderRadius: '8px' }}>
                            <Typography gutterBottom variant="h5" component="div">
                                About
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </Typography>
                        </Paper>

                    </Grid>
                    <Grid key={1} container item xs={12} md={6} spacing={2}>
                        {cardData.map((card, index) => (
                            <Grid key={index} item xs={12} md={6}>
                                <Card sx={{ borderRadius: '8px' }}>
                                    <CardMedia
                                        sx={{ height: 280 }}
                                        image={card.image}
                                        title={card.name}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {card.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {card.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button variant="outlined" size="small">Share</Button>
                                        <Button size="small">Learn More</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid key={2} item xs={12} md={6}>
                        <Paper sx={{ overflow: 'auto', borderRadius: '8px' }}>
                            <TableContainer sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Dessert (100g serving)</TableCell>
                                            <TableCell align="right">Fat(g)</TableCell>
                                            <TableCell align="right">Carbs(g)</TableCell>
                                            <TableCell align="right">Protein(g)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">{row.fat}</TableCell>
                                                <TableCell align="right">{row.carbs}</TableCell>
                                                <TableCell align="right">{row.protein}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid key={3} item md={6} xs={12}>
                        <Paper sx={{ borderRadius: '8px' }}>
                            <AreaChart />
                        </Paper>

                    </Grid>
                    <Grid key={4} item md={6} xs={12}>
                        <Paper sx={{ borderRadius: '8px' }}>
                            <ScatterPlot />
                        </Paper>

                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default LandingPage;